/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
@import 'animations';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  // overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #555555;
}
.wrap {
  padding: 30px 0;
}
.container-fluid.but-fixed {
  max-width: 1230px;
}
#footer-wrap {
  background-color: #f5f5f5;
}
.footer {
  padding-top: 30px;
  padding-bottom: 15px;
  width: 100%;
  color: #6a6a6a;
  border-top: 1px solid #e4e4e4;
  font-size: 0.9rem;
  p {
    margin: 0 0 5px 0;
  }
  a {
    color: #6a6a6a;
  }
  img {
    display: inline-block;
  }
}
.fw-strong {
  font-weight: 600;
}
.border-light {
  border-color: #efefef !important;
}
.bg-light-blue {
  background: #f6f9fb !important;
}

/* --------- asc styles --------- */
#header {
  float: none;
  a.logo {
    float: left;
    margin-top: 15px;
    margin-bottom: 15px;
    border: 0;
    width: 80%;
    max-width: 200px;
    img {
      display: inline-block;
      margin-right: 5px;
      vertical-align: middle;
      width: auto;
      height: auto;
      max-width: 200px;
    }
  }
}
#banner-wrap {
  .homepage-banner {
    width: 300px;
  }

  .details {
    ul,
    p {
      font-size: 1.1em;
    }
  }
}
#steps-wrap {
  .icon-border {
    width: 40px;
    height: 40px;
    border: 1px solid $theme-primary;
    border-radius: 50%;
  }
  .icon {
    width: 20px;
    height: auto;
    vertical-align: middle;
    fill: $theme-primary;
  }
}
#circle-icon {
  width: 180px;
}
.dropdown-list.dropdown-cta {
  &.small-cta {
    max-width: 250px;
  }
}
#cta-wrap,
.gradient-wrap {
  background: $theme-primary;
  background: linear-gradient(30deg, #05446b, #05446b 42%, #0092b2);
}
#cta-wrap {
  .dropdown-list {
    width: 95%;
    max-width: 300px;
    &.small-cta {
      max-width: 250px;
    }
  }
}
.gradient-wrap {
  a {
    color: #70d7ed;
  }
}
blockquote {
  font-size: 1.35rem;
  p {
    font-style: italic;
    line-height: 1.5em;
    &:before,
    &:after {
      margin-right: 5px;
      display: inline-block;
      content: '\201C';
      opacity: 0.5;
      font-family: serif;
      font-size: 32px;
      font-weight: 700;
      vertical-align: middle;
      font-style: normal;
      line-height: initial;
      height: 20px;
      margin-top: -13px;
    }
    &:after {
      margin-left: 5px;
      content: '\201D';
    }
  }
}
.card {
  border-radius: 0.5rem;
  box-shadow: 0 0.1rem 0.4rem rgba(0, 0, 0, 0.18);
  border-top: 5px solid #0092b2;
  border-bottom: none;
  border-left: none;
  border-right: none;
  .card-header {
    background: #fff;
    border-radius: 0.8rem 0.8rem 0 0;
  }
  .card-footer {
    border-radius: 0 0 0.8rem 0.8rem;
  }
}
.radio-btn {
  border: 1px solid $theme-primary;
  max-width: 300px;
  color: $theme-primary;
  background: rgba(255, 255, 255, 0.75);
  cursor: pointer;
  &.active {
    background: $theme-primary;
    color: #fff;
  }
  .form-check-label {
    & > span {
      font-size: 1.15em;
      line-height: 1.3em;
    }
  }
}
#banner-wrap {
  &.food-handler {
    background: #fff url(../images/banners/food-handler-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.food-manager {
    background: #fff url(../images/banners/food-manager-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.alcohol-certification {
    background: #fff url(../images/banners/alcohol-certification-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.seller-server-alcohol {
    background: $theme-primary url(../images/banners/seller-server-alcohol-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.california {
    background: $theme-primary url(../images/banners/california-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.texas-food-handler {
    background: $theme-primary url(../images/banners/texas-food-handler-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.texas-food-manager {
    background: $theme-primary url(../images/banners/texas-food-manager-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.illinois-home {
    background: #fff url(../images/banners/illinois/home-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.il-on-premise {
    background: #fff url(../images/banners/illinois/on-premise2-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.il-off-premise {
    background: #fff url(../images/banners/illinois/off-premise2-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.food-handler-state {
    background: #fff url(../images/banners/food-handler-state-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.ny-on-premise {
    background: #fff url(../images/banners/new-york/on-premise-sm.jpg) no-repeat center/cover;
  }
  &.ny-off-premise {
    background: #fff url(../images/banners/new-york/off-premise-sm.jpg) no-repeat center/cover;
  }
  &.tabc-packages {
    background: #fff url(../images/banners/tabc-packages-bg-sm.jpg) no-repeat 47% 25% / cover;
  }
  &.utah-easy {
    background: #fff url(../images/banners/utah-off-premise-sm.jpg) no-repeat 0% 30% / cover;
  }
  .card {
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
  }
}
.dropdown-list .dropdown {
  transition: 0.2s;
  &.is-active {
    background: $headline-secondary;
    color: #fff;
    a {
      color: #fff;
    }
  }
}
.dropdown-bundle {
  min-width: 300px;
}

.bundle-banner {
  a {
    color: lighten($headline-secondary, 40%);
  }
  &.illinois {
    background: #05446b url(../images/banners/illinois/off-premise-bundle-bg-sm.jpg) no-repeat 22% 22% / cover;
  }
  &.slsv {
    background: #05446b url(../images/banners/seller-server-bundle-bg-sm.jpg) no-repeat 0% 100% / cover;
  }
}

.list-price {
  margin-left: 3px;
  position: relative;
  font-size: 30px;
  font-weight: 300;
  display: inline-block;
  font-family: 'Trebuchet MS', 'Droid Serif', Arial, sans-serif;
  vertical-align: middle;
  &:after {
    display: block;
    content: '';
    width: 110%;
    height: 2px;
    background: #c33;
    position: absolute;
    top: 48%;
    left: -5%;
    transform: rotate(-10deg);
    transform-origin: 40% 0;
  }
  span {
    color: #c0c0c0;
  }
  span.dollar-sign {
    margin: 0;
    font-size: 20px;
    display: inline-block;
    line-height: 40px;
    vertical-align: top;
  }
}
/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  #steps-wrap {
    .icon-border {
      width: 60px;
      height: 60px;
    }
    .icon {
      width: 30px;
      height: auto;
    }
  }
  .btn-width {
    width: 220px;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header a.logo {
    img {
      display: block;
      float: left;
      max-width: 200px;
      margin-right: 15px;
    }
  }
  #banner {
    .homepage-banner {
      width: 500px;
    }
  }
  #banner-wrap {
    &.food-handler {
      background: #fff url(../images/banners/food-handler.jpg) no-repeat 0 0 / cover;
    }
    &.food-handler-state {
      background: #fff url(../images/banners/food-handler-state.jpg) no-repeat 35% 0 / cover;
      &.illinois {
        background-position: 0 0;
      }
    }
    &.food-manager {
      background: #fff url(../images/banners/food-manager.jpg) no-repeat 0 20% / cover;
    }
    &.alcohol-certification {
      background: #fff url(../images/banners/alcohol-certification.jpg) no-repeat 0 0 / cover;
    }
    &.texas-food-handler {
      background: $theme-primary url(../images/banners/texas-food-handler.jpg) no-repeat 0 0 / cover;
    }
    &.texas-food-manager {
      background: $theme-primary url(../images/banners/texas-food-manager.jpg) no-repeat 0 0 / cover;
    }
    &.california {
      background: $theme-primary url(../images/banners/california.jpg) no-repeat 0 0 / cover;
    }
    &.seller-server-alcohol {
      background: $theme-primary url(../images/banners/seller-server-alcohol.jpg) no-repeat center/cover;
    }
    &.illinois-home {
      background: #fff url(../images/banners/illinois/home.jpg) no-repeat center/cover;
    }
    &.il-on-premise {
      background: #fff url(../images/banners/illinois/on-premise.jpg) no-repeat center/cover;
    }
    &.il-off-premise {
      background: #fff url(../images/banners/illinois/off-premise.jpg) no-repeat center/cover;
    }
    &.ny-on-premise {
      background: #fff url(../images/banners/new-york/on-premise.jpg) no-repeat center/cover;
    }
    &.ny-off-premise {
      background: #fff url(../images/banners/new-york/off-premise.jpg) no-repeat center/cover;
    }
    &.tabc-packages {
      background: #fff url(../images/banners/tabc-packages-bg.jpg) no-repeat 0 5% / cover;
    }
    &.utah-easy {
      background: #fff url(../images/banners/utah-off-premise.jpg) no-repeat 75% 100% / cover;
    }
  }
  #steps-wrap {
    .icon-border {
      width: 80px;
      height: 80px;
      border-width: 2px;
    }
    .icon {
      width: 40px;
      height: auto;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #steps-wrap {
    .icon-border {
      width: 120px;
      height: 120px;
      border-width: 3px;
    }
    .icon {
      width: 55px;
      height: auto;
    }
  }
  .bundle-banner {
    &.illinois {
      background: #05446b url(../images/banners/illinois/off-premise-bundle-bg.jpg) no-repeat 80% 25% / cover;
    }
    &.slsv {
      background: #05446b url(../images/banners/seller-server-bundle-bg.jpg) no-repeat right 85% / cover;
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  #nav-wrap {
    border-bottom: 0;
    .login {
      a {
        background: $headline-secondary;
        border-radius: 50px;
        padding: 5px 15px;
        &:hover {
          background: darken($headline-secondary, 5%);
        }
      }
    }
  }
  #banner-wrap {
    &.food-handler-state {
      background: #fff url(../images/banners/food-handler-state.jpg) no-repeat right 0 / cover;
      &.illinois {
        background-position: 0 0;
      }
    }
  }
}

// Extra large devices (large desktops, 1500px and up)
@media (min-width: 1500px) {
  #banner-wrap.large-img {
    background-size: contain !important;
    background-position: 100% 50% !important;
  }
}

@media (min-width: 2000px) {
  #banner-wrap.large-img {
    background-size: cover !important;
    background-position: 100% 20% !important;
  }
}
