/* ----------------------------------------------------------------------------
  Navigation Menu
----------------------------------------------------------------------------- */
#nav-wrap {
  box-shadow: 0px 0px 5px #a7a7a7;
}
#menutoggle {
  display: block;
  margin: 0;
  padding: 0 22px;
  width: auto;
  color: $theme-primary;
  height: 45px;
  font-size: 18px;
  line-height: 45px;
  text-align: left;
  text-transform: uppercase;
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom: 17px;
  border-radius: 4px;
  transition: 0.2s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  span {
    display: none;
    margin-left: 10px;
    vertical-align: middle;
    font-weight: 600;
  }
  .hamburger-box {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 24px;
    vertical-align: middle;
    .hamburger-inner {
      display: block;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
      transition-duration: 75ms;
    }
    .hamburger-inner,
    .hamburger-inner::after,
    .hamburger-inner::before {
      position: absolute;
      width: 30px;
      height: 3px;
      transition-timing-function: ease;
      transition-duration: 0.15s;
      transition-property: transform, width;
      border-radius: 4px;
      background-color: $theme-primary;
      right: 0;
    }
    .hamburger-inner {
      top: 0;
      width: 25px;
    }
    .hamburger-inner::after {
      width: 22px;
    }
    .hamburger-inner::after,
    .hamburger-inner::before {
      display: block;
      content: '';
    }
    .hamburger-inner::before {
      top: 10px;
      transition-timing-function: ease;
      transition-duration: 0.15s;
      transition-property: transform, opacity;
    }
    .hamburger-inner::after {
      top: 20px;
    }
  }
  &.is-active {
    .hamburger-inner,
    .hamburger-inner::after,
    .hamburger-inner::before {
      width: 30px;
    }
    .hamburger-inner {
      transform: translate3d(0, 10px, 0) rotate(45deg);
    }
    .hamburger-inner::before {
      transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
      opacity: 0;
    }
    .hamburger-inner::after {
      transform: translate3d(0, -20px, 0) rotate(-90deg);
    }
  }
  &:hover {
    background-color: $theme-primary;
    color: #fff;
    .hamburger-box {
      .hamburger-inner,
      .hamburger-inner::after,
      .hamburger-inner::before {
        width: 30px;
        background-color: #fff;
        -moz-transition: all 0.2s ease-in;
        -o-transition: all 0.2s ease-in;
        -webkit-transition: all 0.2s ease-in;
        transition: all 0.2s ease-in;
      }
    }
  }
  &.is-active + #nav ul {
    padding-top: 5px;
    // height: 100vh;
    max-height: 1000px;
    opacity: 1;
    transition: max-height 0.3s ease-in-out, opacity 0.25s ease-in-out, padding-top 0.25s ease-in-out;
  }
}
#nav {
  clear: both;
  margin: 0;
  width: 100%;
  height: 0;
  overflow: visible;
  position: relative;
  z-index: 1000;
  ul {
    float: none;
    display: block;
    margin: 0;
    padding: 0;
    width: auto;
    opacity: 0;
    max-height: 0;
    background: $theme-primary;
    overflow: hidden;
    transition: max-height 0.25s cubic-bezier(0, 0.7, 0, 1), opacity 0.2s ease-in-out;
    border-radius: 0 0 4px 4px;
    top: 1px;
    border: 0;
    position: relative;
    &.submenu {
      margin: 0;
      padding: 0;
      width: 100%;
      max-height: 0;
      background: none;
      opacity: 1;
      position: relative;
      top: 0;
      transition: max-height 0.15s linear 0s;
      a {
        padding-left: 40px;
        background: none;
        &:before {
          display: inline-block;
          margin-right: 5px;
          margin-left: -15px;
          width: 10px;
          height: inherit;
          content: '\00BB';
          color: inherit;
          font-family: 'icons';
          text-align: center;
          font-size: inherit;
          vertical-align: top;
        }
      }
    }
  }
  li {
    display: block;
    margin: 0;
    padding: 0;
    height: auto;
    overflow: hidden;
    border: 0;
    border-top: 1px dotted lighten($theme-primary, 10%);
    &:first-child {
      border-top: 0;
    }
    &.onmobile {
      display: block;
    }
  }
  a,
  span.submenu {
    float: none;
    display: block;
    margin: 0;
    padding: 9px 20px;
    height: auto;
    color: #fff;
    line-height: 1.6em;
    text-align: left;
    text-decoration: none;
    font-size: 16px;
    font-weight: normal;
    border: 0;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transition: background 0.17s linear 0s;
  }
  span.submenu:after,
  a.submenu:after {
    float: right;
    margin-left: 10px;
    width: 15px;
    height: inherit;
    content: '\e817';
    color: inherit;
    font-family: 'icons';
    text-align: center;
    font-size: 105%;
    vertical-align: top;
  }
  span.submenu.is-active,
  a.submenu.is-active {
    border-bottom: 1px dotted lighten($theme-primary, 10%);
    + ul.submenu {
      max-height: 360px;
      overflow-y: auto;
    }
  }
  span.submenu.is-active:after,
  a.submenu.is-active:after {
    content: '\e816';
  }
  a:hover,
  a.is-active,
  li:hover span.submenu,
  li:hover a.submenu,
  ul.submenu a:hover,
  ul.submenu a.is-active {
    background: darken($theme-primary, 7.5%);
  }
}

@media (min-width: 36em) {
  /* Small devices (landscape phones, 576px and up) */
  /*@media (min-width: 48em) { /* Medium devices (tablets, 768px and up) */
  #menutoggle {
    padding: 0 15px 0 15px;
    span {
      display: inline-block;
    }
    &:before {
      margin-right: 10px;
    }
  }
}
// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  #menutoggle {
    display: none;
  }
  #nav-wrap {
    .nav-flex {
      display: flex;
    }
    .logo {
      margin-right: 1.5rem;
    }
  }
  #nav {
    height: 40px;
    position: relative;
    z-index: 99;
    border: 0;
    border-radius: 4px;
    ul {
      display: block !important;
      float: left;
      width: 100%;
      height: auto;
      opacity: 1;
      margin: 4px 0;
      position: relative;
      z-index: 10;
      top: 0;
      overflow: visible;
      &.submenu {
        float: left;
        margin: 0;
        padding: 0;
        width: 250px;
        max-height: 0;
        position: absolute;
        opacity: 0;
        background: #fff;
        top: 95%;
        z-index: 10;
        border-radius: 0 3px 3px 3px;
        box-shadow: 0px 4px 5px #878585;
        transition: max-height 0.2s linear 0s, padding 0.2s linear 0s, opacity 0.2s linear 0s;
        overflow: hidden;
        overflow-y: auto;
        li {
          float: none;
          display: block;
          margin: 0;
          padding: 0;
          border: 0;
          height: auto;
          &:first-child a {
            border: 0;
          }
        }
        a {
          float: none;
          display: block;
          margin: 0;
          padding: 7px 15px 7px 28px;
          height: auto;
          text-transform: none;
          text-align: left;
          line-height: 1.5em;
          border-radius: 0;
          border: 0;
          border-top: 1px solid #ddd;
        }
      }
    }
    li {
      float: left;
      margin: 0 5px;
      padding: 0;
      width: auto;
      min-width: auto;
      position: relative;
      border: 0;
      overflow: visible;
      &:first-child {
        margin-left: 0;
        border-left: 0;
      }
      &.last {
        border-right: 0;
      }
      &.right {
        float: right;
        margin-right: 0;
        border-right: 0;
      }
      &.onmobile {
        display: none;
      }
      &:hover {
        z-index: 11;
      }
    }
    a,
    span.submenu {
      float: left;
      margin: 0;
      padding: 5px 10px;
      border: 0;
      color: $theme-primary;
      font-weight: 600;
      font-size: 14px;
      text-align: center;
      border-radius: 3px;
      cursor: pointer;
      transition: 0.2s;
    }
    span.submenu:after,
    a.submenu:after {
      display: none;
    }
    a:hover,
    a.is-active,
    li:hover span.submenu,
    li:hover a.submenu,
    li:hover a.submenu,
    ul.submenu a:hover,
    span.submenu.is-active,
    a.submenu.is-active {
      background: none;
      color: $theme-secondary;
    }
    li:hover a.submenu,
    li:hover span.submenu,
    li:hover a.submenu,
    span.submenu.is-active.hover,
    a.submenu.is-active.hover {
      border-radius: 3px 3px 0 0;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    li:hover ul.submenu,
    ul.submenu.is-active {
      padding: 3px 0;
      left: 0;
      max-height: 360px;
      opacity: 1;
    }
    ul.submenu li a:hover,
    ul.submenu li a.is-active {
      background: darken(#fff, 5%);
    }
  }
}

.offcanvas {
  visibility: hidden;
}
.sidenav {
  box-shadow: 3px 0px 4px #686868;
  ul.submenu {
    list-style: none;
    padding-left: 15px;
    max-height: 0;
    transition: max-height 0.15s linear 0s;
    &.active-mobile {
      max-height: 395px;
      overflow-y: visible;
    }
    li {
      margin: 0;
    }
    li a {
      font-size: 16px;
      font-weight: 600;
      &:after {
        margin-left: 5px;
        font-size: 12px;
        line-height: 26px;
      }
    }
  }
  span.submenu.is-active + ul.submenu {
    max-height: 395px;
    overflow-y: visible;
    li {
      margin: 0.3em 0;
    }
  }
  ul {
    padding-left: 0;
    li {
      overflow: hidden;
      cursor: pointer;
      &:last-child {
        margin-bottom: 0;
      }
    }
    a,
    span {
      padding: 2px 0;
      border: 0;
      color: $theme-primary;
      font-weight: bold;
      font-size: 20px;
      &.submenu {
        &:after {
          content: '\e817';
        }
        &.is-active:after {
          content: '\e816';
        }
      }
      ul.submenu li {
        margin: 0;
      }
      &:after {
        display: inline-block;
        margin-left: 10px;
        width: inherit;
        height: inherit;
        content: '\e815';
        color: $headline-secondary;
        font-family: icons;
        font-weight: 400;
        text-align: center;
        font-size: 16px;
        line-height: 30px;
        vertical-align: top;
      }
      &:hover {
        color: $theme-secondary;
      }
    }
  }
  .support {
    font-size: 18px;
    .livechat,
    .phone {
      &:before {
        display: inline-block;
        margin-right: 10px;
        width: inherit;
        height: inherit;
        content: '\e830';
        color: inherit;
        font-family: icons;
        font-weight: 400;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
    }
    .phone:before {
      content: '\e829';
    }
  }
  .login {
    background: $theme-secondary;
    border: 0;
    padding: 5px 20px;
    border-radius: 50px;
    font-size: 18px;
    &:hover {
      background: darken($theme-secondary, 5%);
    }
  }
  .btn-close {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
