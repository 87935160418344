@charset "UTF-8";
/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Colors
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Custom Colors
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Theme Colors
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    CTA buttons
----------------------------------------------------------------------------- */
.text-theme-primary {
  color: #05446b !important;
}

.text-theme-secondary {
  color: #df0015 !important;
}

.text-headline-primary {
  color: #05446b !important;
}

.text-headline-secondary {
  color: #0092b2 !important;
}

.border-theme-secondary {
  border-color: #df0015 !important;
}

.bg-headline-secondary {
  background: #0092b2 !important;
}

/* ----------------------------------------------------------------------------
    Font Icons
----------------------------------------------------------------------------- */
@font-face {
  font-family: "icons";
  src: url("../fonts/icons.eot?24804043");
  src: url("../fonts/icons.eot?24804043#iefix") format("embedded-opentype"), url("../fonts/icons.woff?24804043") format("woff"), url("../fonts/icons.ttf?24804043") format("truetype"), url("../fonts/icons.svg?24804043#icon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
[class^=icon-]:before, [class*=" icon-"]:before {
  font-family: "icons";
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  /*-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;*/
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-dribbble:before {
  content: "\e801";
}

.icon-instagram:before {
  content: "\e802";
}

.icon-behance:before {
  content: "\e803";
}

.icon-linkedin:before {
  content: "\e804";
}

.icon-facebook:before {
  content: "\e805";
}

.icon-twitter:before {
  content: "\e806";
}

.icon-gplus:before {
  content: "\e807";
}

.icon-pinterest:before {
  content: "\e808";
}

.icon-tumblr:before {
  content: "\e809";
}

.icon-down:before {
  content: "\e810";
}

.icon-left:before {
  content: "\e811";
}

.icon-right:before {
  content: "\e812";
}

.icon-up:before {
  content: "\e813";
}

.icon-left-small:before {
  content: "\e814";
}

.icon-right-small:before {
  content: "\e815";
}

.icon-up-small:before {
  content: "\e816";
}

.icon-down-small:before {
  content: "\e817";
}

.icon-down-thin:before {
  content: "\e818";
}

.icon-left-thin:before {
  content: "\e819";
}

.icon-right-thin:before {
  content: "\e820";
}

.icon-up-thin:before {
  content: "\e821";
}

.icon-down-arrow:before {
  content: "\e822";
}

.icon-left-arrow:before {
  content: "\e823";
}

.icon-right-arrow:before {
  content: "\e824";
}

.icon-up-arrow:before {
  content: "\e825";
}

.icon-menu:before {
  content: "\e826";
}

.icon-home:before {
  content: "\e827";
}

.icon-lock:before {
  content: "\e828";
}

.icon-phone:before {
  content: "\e829";
}

.icon-chat:before {
  content: "\e830";
}

.icon-mail:before {
  content: "\e831";
}

.icon-comment:before {
  content: "\e832";
}

.icon-at:before {
  content: "\e833";
}

.icon-star:before {
  content: "\e834";
}

.icon-search:before {
  content: "\e835";
}

.icon-zoom:before {
  content: "\e836";
}

.icon-cog:before {
  content: "\e837";
}

.icon-link:before {
  content: "\e838";
}

.icon-share:before {
  content: "\e839";
}

.icon-check:before {
  content: "\e840";
}

.icon-cancel:before {
  content: "\e841";
}

.icon-plus:before {
  content: "\e842";
}

.icon-minus:before {
  content: "\e843";
}

.icon-close:before {
  content: "\e844";
}

.icon-user:before {
  content: "\e845";
}

.icon-spinner:before {
  content: "\e846";
}

a.social {
  display: inline-block;
  height: 50px;
  width: 50px;
  margin: 5px;
  color: #fff !important;
  background-color: #ccc;
  background-color: rgba(0, 0, 0, 0.2);
  font-size: 22px;
  text-align: center;
  overflow: hidden;
  vertical-align: middle;
  border: 0 !important;
  border-radius: 30px;
  transition: all 0.15s linear 0s;
}

a.social [class^=icon-]:before {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 50px;
}

a.social:hover, a.social:active {
  font-size: 24px;
  border-radius: 4px;
  /*transform: rotate(360deg);*/
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a.social.dribbble:hover, a.social.dribbble:active {
  background-color: #EA4C89;
}

a.social.instagram:hover, a.social.instagram:active {
  background-color: #49789D;
}

a.social.behance:hover, a.social.behance:active {
  background-color: #1769FF;
}

a.social.facebook:hover, a.social.facebook:active {
  background-color: #3f5495;
}

a.social.twitter:hover, a.social.twitter:active {
  background-color: #5d87c4;
}

a.social.linkedin:hover, a.social.linkedin:active {
  background-color: #2575b2;
}

a.social.gplus:hover, a.social.gplus:active {
  background-color: #cc4436;
}

a.social.pinterest:hover, a.social.pinterest:active {
  background-color: #c2142f;
}

a.social.tumblr:hover, a.social.tumblr:active {
  background-color: #529ECC;
}

/* open-sans-300 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-300.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-300.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-300.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-300.woff") format("woff"), url("../fonts/open-sans-v18-latin-300.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-300.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-regular - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-regular.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-regular.woff") format("woff"), url("../fonts/open-sans-v18-latin-regular.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-regular.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-italic.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-italic.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-italic.woff") format("woff"), url("../fonts/open-sans-v18-latin-italic.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-italic.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-600 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-600.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-600.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-600.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-600.woff") format("woff"), url("../fonts/open-sans-v18-latin-600.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-600.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-600italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-600italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-600italic.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-600italic.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-600italic.woff") format("woff"), url("../fonts/open-sans-v18-latin-600italic.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-600italic.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-700.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-700.woff") format("woff"), url("../fonts/open-sans-v18-latin-700.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-700.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-700italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-700italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-700italic.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-700italic.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-700italic.woff") format("woff"), url("../fonts/open-sans-v18-latin-700italic.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-700italic.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* fjalla-one-regular - latin */
@font-face {
  font-family: "Fjalla One";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/fjalla-one-v8-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/fjalla-one-v8-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/fjalla-one-v8-latin-regular.woff2") format("woff2"), url("../fonts/fjalla-one-v8-latin-regular.woff") format("woff"), url("../fonts/fjalla-one-v8-latin-regular.ttf") format("truetype"), url("../fonts/fjalla-one-v8-latin-regular.svg#FjallaOne") format("svg"); /* Legacy iOS */
}
/* roboto-slab-regular - latin */
@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/roboto-slab-v13-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/roboto-slab-v13-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-slab-v13-latin-regular.woff2") format("woff2"), url("../fonts/roboto-slab-v13-latin-regular.woff") format("woff"), url("../fonts/roboto-slab-v13-latin-regular.ttf") format("truetype"), url("../fonts/roboto-slab-v13-latin-regular.svg#RobotoSlab") format("svg"); /* Legacy iOS */
}
/* roboto-slab-600 - latin */
@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/roboto-slab-v13-latin-600.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/roboto-slab-v13-latin-600.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-slab-v13-latin-600.woff2") format("woff2"), url("../fonts/roboto-slab-v13-latin-600.woff") format("woff"), url("../fonts/roboto-slab-v13-latin-600.ttf") format("truetype"), url("../fonts/roboto-slab-v13-latin-600.svg#RobotoSlab") format("svg"); /* Legacy iOS */
}
/* roboto-slab-700 - latin */
@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/roboto-slab-v13-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/roboto-slab-v13-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-slab-v13-latin-700.woff2") format("woff2"), url("../fonts/roboto-slab-v13-latin-700.woff") format("woff"), url("../fonts/roboto-slab-v13-latin-700.ttf") format("truetype"), url("../fonts/roboto-slab-v13-latin-700.svg#RobotoSlab") format("svg"); /* Legacy iOS */
}
/* ----------------------------------------------------------------------------
    CSS Variable, needs to be set in :root
----------------------------------------------------------------------------- */
:root {
  --font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

/* ----------------------------------------------------------------------------
    Smooth Scrolling
----------------------------------------------------------------------------- */
@media (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}
[id] {
  scroll-margin-top: 1rem;
}

/* ----------------------------------------------------------------------------
    Typography
----------------------------------------------------------------------------- */
body {
  line-height: 1.6;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 700;
  font-family: var(--font-family);
  line-height: 1.4;
  color: #05446b;
}

h1,
.h1 {
  font-size: 1.602em;
}

h2,
.h2 {
  font-size: 1.424em;
}

h3,
.h3 {
  font-size: 1.266em;
}

h4,
.h4 {
  font-size: 1.125em;
}

h5,
.h5 {
  font-size: 1em;
}

@media (min-width: 48em) {
  /* Medium devices (tablets, 768px and up) */
  h1,
  .h1 {
    font-size: 1.8em;
  }
  h2,
  .h2 {
    font-size: 1.55em;
  }
  h3,
  .h3 {
    font-size: 1.32em;
  }
  h4,
  .h4 {
    font-size: 1.125em;
  }
  h5,
  .h5 {
    font-size: 1em;
  }
}
@media (min-width: 62em) {
  /* Large devices (desktops, 992px and up) */
  h1,
  .h1 {
    font-size: 2.074em;
  }
  h2,
  .h2 {
    font-size: 1.728em;
  }
  h3,
  .h3 {
    font-size: 1.44em;
  }
  h4,
  .h4 {
    font-size: 1.2em;
  }
  h5,
  .h5 {
    font-size: 1.1em;
  }
}
@media (min-width: 75em) {
  /* Extra large devices (large desktops, 1200px and up) */
  h1,
  .h1 {
    font-size: 2.441em;
  }
  h2,
  .h2 {
    font-size: 1.953em;
  }
  h3,
  .h3 {
    font-size: 1.563em;
  }
  h4,
  .h4 {
    font-size: 1.25em;
  }
  h5,
  .h5 {
    font-size: 1.1em;
  }
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

a {
  color: #5f95dd;
  text-decoration: none;
  outline: none !important;
}

a:hover,
a:active {
  text-decoration: none;
  border-bottom-width: 1px;
  border-bottom-style: dotted;
  border-bottom-color: inherit;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: #3579d4;
}

a.img:hover,
a.img:active {
  text-decoration: none;
  border: 0;
}

a[href^="tel:"] {
  color: inherit;
  border: 0;
}

ul.nav,
ol.nav {
  margin-left: 0;
  margin-top: 1em;
  margin-bottom: 1em;
}

.cfix:after,
.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

#content,
#main_content,
.container,
.container-fluid,
.break_word {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

/* ----------------------------------------------------------------------------
    Forms Elements
----------------------------------------------------------------------------- */
.opacity-1 {
  opacity: 1 !important;
}

.display-none {
  display: none !important;
}

/* ----------------------------------------------------------------------------
    Buttons
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Tables
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Modals
----------------------------------------------------------------------------- */
.modal-header {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.modal-header .close {
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
  margin-top: -0.7rem;
}

.modal-body .btn-close {
  opacity: 1;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  text-align: center;
  z-index: 100;
  background-color: #ffffff;
  border-bottom-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

/* ----------------------------------------------------------------------------
  Dropdown List
----------------------------------------------------------------------------- */
.dropdown-list {
  margin: auto;
  width: auto;
  position: relative;
  box-sizing: border-box;
}

.dropdown-list ul.drop {
  margin: 0;
  padding: 0;
  list-style: none;
}

.dropdown-list li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.dropdown-list span.dropdown {
  display: block;
  margin: 0;
  padding: 0 10px;
  width: auto;
  height: 40px;
  line-height: 36px;
  background: #fff;
  border-radius: 50px;
  border: 2px solid #0092b2;
  cursor: pointer;
  overflow: hidden;
  color: #0092b2;
}

.dropdown-list span.dropdown:hover,
.dropdown-list span.dropdown:active {
  background: #0092b2;
  color: #fff;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.dropdown-list span.dropdown:after {
  float: right;
  margin-top: 0;
  margin-right: 10px;
  width: 15px;
  height: 100%;
  content: "\e818";
  color: ineherit;
  font-family: "icons";
  text-align: center;
  font-size: inherit;
  vertical-align: top;
}

.dropdown-list span.dropdown.is-active:after {
  content: "\e821";
}

.dropdown-list a:hover {
  text-decoration: none;
  border-bottom: 0;
}

.dropdown-list .drop {
  display: block;
  float: left;
  margin: 0;
  width: calc(100% - 37px);
  left: 18px;
  max-height: 0;
  position: absolute;
  background: #fff;
  top: 40px;
  border-radius: 0 0 3px 3px;
  z-index: 10;
  overflow-y: auto;
  opacity: 0;
  transition: max-height 0.3s cubic-bezier(0, 0.6, 0, 1), opacity 0.3s cubic-bezier(0, 0.6, 0, 1);
}

.dropdown-list span.is-active + .drop {
  max-height: 350px;
  border: 1px solid #0092b2;
  border-top: 0;
  opacity: 1;
  transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 5px, rgba(0, 0, 0, 0.24) 0 1px 4px;
}

.dropdown-list .drop li {
  float: none;
  display: block;
  margin: 0;
  padding: 0;
  border: 0;
  height: auto;
  border-top: 1px solid #ccc;
}

.dropdown-list .drop li:first-child {
  border-top: 0;
}

.dropdown-list .drop li a {
  float: none;
  display: block;
  margin: 0;
  padding: 6px 10px 6px 25px;
  height: auto;
  width: auto;
  text-transform: none;
  color: inherit;
  background: #fff;
  text-align: left;
  border-radius: 0;
  border: 0;
}

.dropdown-list .drop li a:hover,
.dropdown-list .drop li a:active {
  background: #f1f1f1;
}

.dropdown-list .drop li a:before {
  display: inline-block;
  margin-left: -15px;
  margin-right: 5px;
  width: 10px;
  height: inherit;
  content: "\e815";
  color: #0092b2;
  font-family: "icons";
  text-align: center;
  font-size: inherit;
  vertical-align: top;
}

.dropdown-list.dropdown-cta {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  max-width: 325px;
}
.dropdown-list.dropdown-cta span.dropdown {
  padding: 13px 20px;
  padding-bottom: 14px;
  color: #fff;
  font-weight: 400;
  background-color: #d00014;
  border: 0;
  width: 100%;
  height: auto;
  line-height: 1;
  font-size: 18px;
  text-align: left;
  text-transform: normal;
  transition: background-image 0.1s ease-in-out 0.05s;
}
.dropdown-list.dropdown-cta span.dropdown + .drop {
  top: 45px;
}
.dropdown-list.dropdown-cta span.dropdown:hover, .dropdown-list.dropdown-cta span.dropdown.is-active {
  background: #bb0012;
}

.dropdown-list.drop-up span.dropdown:after {
  content: "\e816";
}
.dropdown-list.drop-up span.dropdown.is-active:after {
  content: "\e817";
}
.dropdown-list.drop-up.dropdown-cta span.dropdown + .drop {
  top: auto;
  bottom: 45px;
}
.dropdown-list.drop-up .drop {
  bottom: 39px;
  top: auto;
  border-top: 1px solid #a1c4e5 !important;
  border-radius: 3px 3px 0 0;
}

ul,
ol {
  padding-left: 30px;
}

ul li,
ol li {
  margin-top: 0.3em;
  margin-bottom: 0.3em;
}

ul.check,
ul.star,
ul.carrot {
  margin-left: 0;
  padding-left: 0;
  list-style: none outside;
  line-height: inherit;
}

ul.check li,
ul.star li,
ul.carrot li {
  padding-left: 1.3em;
  margin-left: 0;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
  position: relative;
}

ul.check li li,
ul.star li li,
ul.carrot li li {
  padding-left: 0;
}

ul.carrot li {
  padding-left: 0.9em;
}

ul.check li:before,
ul.star > li:before,
ul.carrot > li:before {
  display: block;
  margin-top: 0.1em;
  width: 1em;
  height: inherit;
  color: #0092b2;
  font-size: 0.9em;
  font-weight: normal !important;
  word-wrap: normal;
  position: absolute;
  left: 0;
}

ul.check li:before {
  content: "\e840";
  font-family: "icons";
}

ul.star > li:before {
  content: "\e834";
  font-family: "icons";
}

ul.carrot > li:before {
  content: "»";
  font-family: "icons";
  margin-top: 0.2em;
  font-size: 1em;
  font-weight: 600;
  line-height: 1;
  width: 1em;
}

/* ----------------------------------------------------------------------------
  Accordion
----------------------------------------------------------------------------- */
.accordion-wrap {
  margin-bottom: 1rem;
}
.accordion-wrap .accordion {
  padding: 0;
  border-bottom: 1px dotted #ccc;
  overflow: hidden;
}
.accordion-wrap .accordion:first-child {
  border-top: 1px dotted #ccc;
}
.accordion-wrap .accordion .accordion-toggle {
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 10px;
  padding-right: calc(1em + 10px);
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  overflow: hidden;
  font-weight: 600;
}
.accordion-wrap .accordion .accordion-toggle:before {
  float: right;
  color: inherit;
  content: "\e817";
  font-family: "icons";
  font-size: 1em;
  font-weight: normal !important;
  height: inherit;
  width: 1em;
  margin-right: -1em;
  text-align: right;
}
.accordion-wrap .accordion .accordion-toggle:hover {
  background-color: rgba(0, 0, 0, 0.02);
}
.accordion-wrap .accordion.is-open > .accordion-content {
  height: auto;
  opacity: 1;
  transform: scaleY(1);
  transition: transform 0.1s ease-in-out, height 0.2s ease-in-out, opacity 0.2s ease-in-out;
}
.accordion-wrap .accordion.is-open > .accordion-toggle:before {
  content: "\e816";
}
.accordion-wrap .accordion .accordion-content {
  padding: 0 10px;
  height: 0;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.2s ease-in-out, height 0.3s cubic-bezier(0, 0.6, 0, 1), opacity 0.3s cubic-bezier(0, 0.6, 0, 1);
  overflow: hidden;
}

/* ----------------------------------------------------------------------------
  Bootstrap Overrides
----------------------------------------------------------------------------- */
b,
strong {
  font-weight: 600;
}

.modal-header {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.modal-header h1,
.modal-header h2,
.modal-header h3,
.modal-header h4,
.modal-header h5 {
  margin-top: 0;
  font-weight: 400;
}

input[type=number] {
  appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::placeholder {
  color: #e0e0e0 !important;
}

.custom-control.is-invalid .custom-control-input ~ .custom-control-label, .custom-control:invalid .custom-control-input ~ .custom-control-label {
  color: #fb4f16;
}
.custom-control.is-invalid .custom-control-input ~ .custom-control-label:before, .custom-control:invalid .custom-control-input ~ .custom-control-label:before {
  border-color: #fb4f16;
}
.custom-control.is-invalid .custom-control-input:focus ~ .custom-control-label:before, .custom-control:invalid .custom-control-input:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.custom-control.custom-checkbox {
  padding-left: 1.75rem;
}
.custom-control.custom-checkbox .custom-control-label:before, .custom-control.custom-checkbox .custom-control-label:after {
  left: -1.75rem;
  width: 1.2rem;
  height: 1.2rem;
}
.custom-control.custom-checkbox .custom-control-input:checked ~ .custom-control-label:before {
  border-color: #316ce8;
  background-color: #316ce8;
}
.custom-control.custom-radio {
  padding-left: 1.75rem;
}
.custom-control.custom-radio .custom-control-label:before, .custom-control.custom-radio .custom-control-label:after {
  left: -1.75rem;
  width: 1.2rem;
  height: 1.2rem;
}
.custom-control.custom-radio .custom-control-input:checked ~ .custom-control-label:before {
  border-color: #316ce8;
  background-color: #316ce8;
}

.form-control.is-invalid {
  border-color: #fb4f16;
}
.form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  background-image: none;
}

.custom-select.is-invalid {
  border-color: #fb4f16;
}
.custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #80bdff;
  box-shadow: none;
}

.table {
  word-wrap: normal;
  word-break: normal;
}
.table.align-middle th,
.table.align-middle td {
  vertical-align: middle;
}
.table.table-sm td,
.table.table-sm th {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.list-group-item {
  margin-top: 0;
  margin-bottom: 0;
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1200px;
  }
}
/* ----------------------------------------------------------------------------
  ASC Default stuff
----------------------------------------------------------------------------- */
.price {
  display: inline-block;
  padding: 0;
  width: auto;
  height: auto;
  color: #555;
  text-align: center;
  font: bold 0 "Trebuchet MS", "Droid Serif", Arial, sans-serif;
  overflow: visible;
  vertical-align: middle;
  color: transparent;
}

.price.static {
  color: #555;
}

.price.shown {
  color: #555;
  transition: color ease-in-out 0.1s, text-shadow ease-in-out 0.1s;
}

.price.ribbon {
  margin-top: -6px;
  margin-bottom: 0;
  padding: 28px 0 0 0;
  width: 140px;
  height: 93px;
  color: #fff;
  background: url(../images/price_bg.png) no-repeat 0 0;
  text-shadow: 0 0 25px #fff;
  color: transparent;
}

.price.burst {
  padding: 60px 0 0 0;
  width: 155px;
  height: 155px;
  color: #fff;
  background: url(../images/price_bg_burst.png) no-repeat 50% 50%;
  text-shadow: 0 0 25px #fff;
  color: transparent;
}

.price.ribbon.static,
.price.burst.static {
  color: #fff;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
}

.price.ribbon.shown,
.price.burst.shown {
  color: #fff;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
  transition: color ease-in-out 0.1s, text-shadow ease-in-out 0.1s;
}

.price span.dollar-sign,
.price span.cents,
.price span.cents-fees,
.price span.only {
  display: inline-block;
  margin: 5px 0 0 0;
  font-size: 30px;
  line-height: 30px;
  vertical-align: top;
}

.price span.only {
  margin-bottom: 5px;
  font-size: 26px;
  display: block;
}

.price span.dollars,
.price span.dollars-fees {
  display: inline-block;
  margin-left: -1px;
  font-size: 50px;
  line-height: 50px;
  letter-spacing: -1px;
  vertical-align: middle;
}

.price span.cents,
.price span.cents-fees {
  margin: 4px 0 0 3px;
  letter-spacing: -1px;
}

.price.shown span.reg-price {
  font-size: 14px;
  font-weight: 400;
  color: #777;
  text-decoration: line-through;
  padding-left: 3px;
  vertical-align: middle;
  text-shadow: none;
}

.price.ribbon span.reg-price {
  color: #eee;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
}

.price span.dollars[data-pid]:after,
.price span.dollars-fees[data-pid]:after,
.price span.cents[data-pid]:after,
.price span.cents-fees[data-pid]:after,
.price span.dollars[data-pkgid]:after,
.price span.cents[data-pkgid]:after {
  display: inline-block;
  content: "##";
}

.price.shown span.dollars[data-pid]:after,
.price.shown span.dollars-fees[data-pid]:after,
.price.shown span.cents[data-pid]:after,
.price.shown span.cents-fees[data-pid]:after,
.price.shown span.dollars[data-pkgid]:after,
.price.shown span.cents[data-pkgid]:after {
  display: none;
}

.price.circle {
  padding-top: 20px;
  height: 130px;
  width: 130px;
  border: 1px solid #03253a;
  border-radius: 50%;
  background-color: #e8e4e3;
}
.price.circle .price-text {
  display: block;
  text-shadow: none;
  font-size: 0.9rem;
  font-weight: normal;
  margin-bottom: 10px;
}
.price.circle .price-text:after {
  padding-top: 8px;
  content: "";
  display: block;
  margin: 0 auto;
  width: 45%;
  border-bottom: 2px solid #bd0000;
}

/* -- for dynamic LGAs -- */
.lga-item {
  display: none;
  opacity: 0;
}
.lga-item.lga-replaced {
  display: inline;
  opacity: 1;
}

/* ----------------------------------------------------------------------------
  Navigation Menu
----------------------------------------------------------------------------- */
#nav-wrap {
  box-shadow: 0px 0px 5px #a7a7a7;
}

#menutoggle {
  display: block;
  margin: 0;
  padding: 0 22px;
  width: auto;
  color: #05446b;
  height: 45px;
  font-size: 18px;
  line-height: 45px;
  text-align: left;
  text-transform: uppercase;
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom: 17px;
  border-radius: 4px;
  transition: 0.2s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
#menutoggle span {
  display: none;
  margin-left: 10px;
  vertical-align: middle;
  font-weight: 600;
}
#menutoggle .hamburger-box {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 24px;
  vertical-align: middle;
}
#menutoggle .hamburger-box .hamburger-inner {
  display: block;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-duration: 75ms;
}
#menutoggle .hamburger-box .hamburger-inner,
#menutoggle .hamburger-box .hamburger-inner::after,
#menutoggle .hamburger-box .hamburger-inner::before {
  position: absolute;
  width: 30px;
  height: 3px;
  transition-timing-function: ease;
  transition-duration: 0.15s;
  transition-property: transform, width;
  border-radius: 4px;
  background-color: #05446b;
  right: 0;
}
#menutoggle .hamburger-box .hamburger-inner {
  top: 0;
  width: 25px;
}
#menutoggle .hamburger-box .hamburger-inner::after {
  width: 22px;
}
#menutoggle .hamburger-box .hamburger-inner::after,
#menutoggle .hamburger-box .hamburger-inner::before {
  display: block;
  content: "";
}
#menutoggle .hamburger-box .hamburger-inner::before {
  top: 10px;
  transition-timing-function: ease;
  transition-duration: 0.15s;
  transition-property: transform, opacity;
}
#menutoggle .hamburger-box .hamburger-inner::after {
  top: 20px;
}
#menutoggle.is-active .hamburger-inner,
#menutoggle.is-active .hamburger-inner::after,
#menutoggle.is-active .hamburger-inner::before {
  width: 30px;
}
#menutoggle.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}
#menutoggle.is-active .hamburger-inner::before {
  transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
  opacity: 0;
}
#menutoggle.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(-90deg);
}
#menutoggle:hover {
  background-color: #05446b;
  color: #fff;
}
#menutoggle:hover .hamburger-box .hamburger-inner,
#menutoggle:hover .hamburger-box .hamburger-inner::after,
#menutoggle:hover .hamburger-box .hamburger-inner::before {
  width: 30px;
  background-color: #fff;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
#menutoggle.is-active + #nav ul {
  padding-top: 5px;
  max-height: 1000px;
  opacity: 1;
  transition: max-height 0.3s ease-in-out, opacity 0.25s ease-in-out, padding-top 0.25s ease-in-out;
}

#nav {
  clear: both;
  margin: 0;
  width: 100%;
  height: 0;
  overflow: visible;
  position: relative;
  z-index: 1000;
}
#nav ul {
  float: none;
  display: block;
  margin: 0;
  padding: 0;
  width: auto;
  opacity: 0;
  max-height: 0;
  background: #05446b;
  overflow: hidden;
  transition: max-height 0.25s cubic-bezier(0, 0.7, 0, 1), opacity 0.2s ease-in-out;
  border-radius: 0 0 4px 4px;
  top: 1px;
  border: 0;
  position: relative;
}
#nav ul.submenu {
  margin: 0;
  padding: 0;
  width: 100%;
  max-height: 0;
  background: none;
  opacity: 1;
  position: relative;
  top: 0;
  transition: max-height 0.15s linear 0s;
}
#nav ul.submenu a {
  padding-left: 40px;
  background: none;
}
#nav ul.submenu a:before {
  display: inline-block;
  margin-right: 5px;
  margin-left: -15px;
  width: 10px;
  height: inherit;
  content: "»";
  color: inherit;
  font-family: "icons";
  text-align: center;
  font-size: inherit;
  vertical-align: top;
}
#nav li {
  display: block;
  margin: 0;
  padding: 0;
  height: auto;
  overflow: hidden;
  border: 0;
  border-top: 1px dotted #07639c;
}
#nav li:first-child {
  border-top: 0;
}
#nav li.onmobile {
  display: block;
}
#nav a,
#nav span.submenu {
  float: none;
  display: block;
  margin: 0;
  padding: 9px 20px;
  height: auto;
  color: #fff;
  line-height: 1.6em;
  text-align: left;
  text-decoration: none;
  font-size: 16px;
  font-weight: normal;
  border: 0;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: background 0.17s linear 0s;
}
#nav span.submenu:after,
#nav a.submenu:after {
  float: right;
  margin-left: 10px;
  width: 15px;
  height: inherit;
  content: "\e817";
  color: inherit;
  font-family: "icons";
  text-align: center;
  font-size: 105%;
  vertical-align: top;
}
#nav span.submenu.is-active,
#nav a.submenu.is-active {
  border-bottom: 1px dotted #07639c;
}
#nav span.submenu.is-active + ul.submenu,
#nav a.submenu.is-active + ul.submenu {
  max-height: 360px;
  overflow-y: auto;
}
#nav span.submenu.is-active:after,
#nav a.submenu.is-active:after {
  content: "\e816";
}
#nav a:hover,
#nav a.is-active,
#nav li:hover span.submenu,
#nav li:hover a.submenu,
#nav ul.submenu a:hover,
#nav ul.submenu a.is-active {
  background: #032d46;
}

@media (min-width: 36em) {
  /* Small devices (landscape phones, 576px and up) */
  /*@media (min-width: 48em) { /* Medium devices (tablets, 768px and up) */
  #menutoggle {
    padding: 0 15px 0 15px;
  }
  #menutoggle span {
    display: inline-block;
  }
  #menutoggle:before {
    margin-right: 10px;
  }
}
@media (min-width: 1200px) {
  #menutoggle {
    display: none;
  }
  #nav-wrap .nav-flex {
    display: flex;
  }
  #nav-wrap .logo {
    margin-right: 1.5rem;
  }
  #nav {
    height: 40px;
    position: relative;
    z-index: 99;
    border: 0;
    border-radius: 4px;
  }
  #nav ul {
    display: block !important;
    float: left;
    width: 100%;
    height: auto;
    opacity: 1;
    margin: 4px 0;
    position: relative;
    z-index: 10;
    top: 0;
    overflow: visible;
  }
  #nav ul.submenu {
    float: left;
    margin: 0;
    padding: 0;
    width: 250px;
    max-height: 0;
    position: absolute;
    opacity: 0;
    background: #fff;
    top: 95%;
    z-index: 10;
    border-radius: 0 3px 3px 3px;
    box-shadow: 0px 4px 5px #878585;
    transition: max-height 0.2s linear 0s, padding 0.2s linear 0s, opacity 0.2s linear 0s;
    overflow: hidden;
    overflow-y: auto;
  }
  #nav ul.submenu li {
    float: none;
    display: block;
    margin: 0;
    padding: 0;
    border: 0;
    height: auto;
  }
  #nav ul.submenu li:first-child a {
    border: 0;
  }
  #nav ul.submenu a {
    float: none;
    display: block;
    margin: 0;
    padding: 7px 15px 7px 28px;
    height: auto;
    text-transform: none;
    text-align: left;
    line-height: 1.5em;
    border-radius: 0;
    border: 0;
    border-top: 1px solid #ddd;
  }
  #nav li {
    float: left;
    margin: 0 5px;
    padding: 0;
    width: auto;
    min-width: auto;
    position: relative;
    border: 0;
    overflow: visible;
  }
  #nav li:first-child {
    margin-left: 0;
    border-left: 0;
  }
  #nav li.last {
    border-right: 0;
  }
  #nav li.right {
    float: right;
    margin-right: 0;
    border-right: 0;
  }
  #nav li.onmobile {
    display: none;
  }
  #nav li:hover {
    z-index: 11;
  }
  #nav a,
  #nav span.submenu {
    float: left;
    margin: 0;
    padding: 5px 10px;
    border: 0;
    color: #05446b;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    border-radius: 3px;
    cursor: pointer;
    transition: 0.2s;
  }
  #nav span.submenu:after,
  #nav a.submenu:after {
    display: none;
  }
  #nav a:hover,
  #nav a.is-active,
  #nav li:hover span.submenu,
  #nav li:hover a.submenu,
  #nav li:hover a.submenu,
  #nav ul.submenu a:hover,
  #nav span.submenu.is-active,
  #nav a.submenu.is-active {
    background: none;
    color: #df0015;
  }
  #nav li:hover a.submenu,
  #nav li:hover span.submenu,
  #nav li:hover a.submenu,
  #nav span.submenu.is-active.hover,
  #nav a.submenu.is-active.hover {
    border-radius: 3px 3px 0 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  #nav li:hover ul.submenu,
  #nav ul.submenu.is-active {
    padding: 3px 0;
    left: 0;
    max-height: 360px;
    opacity: 1;
  }
  #nav ul.submenu li a:hover,
  #nav ul.submenu li a.is-active {
    background: #f2f2f2;
  }
}
.offcanvas {
  visibility: hidden;
}

.sidenav {
  box-shadow: 3px 0px 4px #686868;
}
.sidenav ul.submenu {
  list-style: none;
  padding-left: 15px;
  max-height: 0;
  transition: max-height 0.15s linear 0s;
}
.sidenav ul.submenu.active-mobile {
  max-height: 395px;
  overflow-y: visible;
}
.sidenav ul.submenu li {
  margin: 0;
}
.sidenav ul.submenu li a {
  font-size: 16px;
  font-weight: 600;
}
.sidenav ul.submenu li a:after {
  margin-left: 5px;
  font-size: 12px;
  line-height: 26px;
}
.sidenav span.submenu.is-active + ul.submenu {
  max-height: 395px;
  overflow-y: visible;
}
.sidenav span.submenu.is-active + ul.submenu li {
  margin: 0.3em 0;
}
.sidenav ul {
  padding-left: 0;
}
.sidenav ul li {
  overflow: hidden;
  cursor: pointer;
}
.sidenav ul li:last-child {
  margin-bottom: 0;
}
.sidenav ul a,
.sidenav ul span {
  padding: 2px 0;
  border: 0;
  color: #05446b;
  font-weight: bold;
  font-size: 20px;
}
.sidenav ul a.submenu:after,
.sidenav ul span.submenu:after {
  content: "\e817";
}
.sidenav ul a.submenu.is-active:after,
.sidenav ul span.submenu.is-active:after {
  content: "\e816";
}
.sidenav ul a ul.submenu li,
.sidenav ul span ul.submenu li {
  margin: 0;
}
.sidenav ul a:after,
.sidenav ul span:after {
  display: inline-block;
  margin-left: 10px;
  width: inherit;
  height: inherit;
  content: "\e815";
  color: #0092b2;
  font-family: icons;
  font-weight: 400;
  text-align: center;
  font-size: 16px;
  line-height: 30px;
  vertical-align: top;
}
.sidenav ul a:hover,
.sidenav ul span:hover {
  color: #df0015;
}
.sidenav .support {
  font-size: 18px;
}
.sidenav .support .livechat:before,
.sidenav .support .phone:before {
  display: inline-block;
  margin-right: 10px;
  width: inherit;
  height: inherit;
  content: "\e830";
  color: inherit;
  font-family: icons;
  font-weight: 400;
  text-align: center;
  font-size: 105%;
  vertical-align: top;
}
.sidenav .support .phone:before {
  content: "\e829";
}
.sidenav .login {
  background: #df0015;
  border: 0;
  padding: 5px 20px;
  border-radius: 50px;
  font-size: 18px;
}
.sidenav .login:hover {
  background: #c60013;
}
.sidenav .btn-close {
  position: absolute;
  top: 10px;
  right: 10px;
}

/* ----------------------------------------------------------------------------
	Buttons
----------------------------------------------------------------------------- */
@keyframes btnProcessing {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.cta-btn {
  display: inline-block;
  padding: 10px 0 8px 0;
  color: #fff;
  background-color: #555555;
  text-align: center;
  position: relative;
  width: 100%;
  max-width: 220px;
  line-height: 1.35;
  font-size: 21px;
  font-weight: normal;
  font-family: "Fjalla One", Impact, Verdana;
  text-transform: uppercase;
  letter-spacing: 0.01em;
  border-radius: 4px;
  cursor: pointer;
  vertical-align: middle;
  text-decoration: none;
  overflow: hidden;
  transition: background-color 0.1s ease-in-out 0.05s;
  border-bottom: 0;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}
.cta-btn:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, #3c3c3c 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform 0.3s, opacity 0.5s;
}
.cta-btn:active:after {
  transform: scale(0, 0);
  opacity: 0.2;
  transition: 0s;
}
.cta-btn[data-pid] {
  position: relative;
  transition: padding-left 0.3s, opacity 0.5s;
}
.cta-btn[data-pid].btn-processing:before {
  display: inline-block;
  margin-right: 7px;
  content: "";
  top: 50%;
  left: 0.7rem;
  margin-top: -10px;
  width: 20px;
  height: 20px;
  transform: translateY(-50%);
  border-radius: 20px;
  border: 3px solid #fff;
  border-top-color: black;
  border-left-color: black;
  opacity: 0.5;
  animation: btnProcessing 1s linear infinite;
}
.cta-btn.disabled {
  background: #ddd !important;
  pointer-events: none;
  color: #5f5f5f !important;
}

.cta-btn.fancy {
  background: #555555;
  background-image: linear-gradient(dimgray, #3c3c3c);
}

.cta-btn:hover,
.cta-btn:active {
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background: #434343;
  border: 0;
}

.cta-btn.big {
  padding: 13px 0 10px 0;
}

.cta-btn.big.outline {
  padding: 12px 0 9px 0;
}

.cta-btn.small {
  padding: 9px 0 7px 0;
  font-size: 18px;
}

.cta-btn.small.fancy {
  line-height: 41px;
}

.cta-btn.small.outline {
  padding: 8px 0 6px 0;
}

.cta-btn.dark {
  color: #fff;
  background: #555555;
}

.cta-btn.orange {
  color: #fff;
  background: #ff6633;
}

.cta-btn.yellow {
  color: #333;
  background: #ffc107;
}

.cta-btn.green {
  color: #fff;
  background: #5cb865;
}

.cta-btn.red {
  color: #fff;
  background: #d00014;
}

.cta-btn.blue {
  color: #fff;
  background: #0099ff;
}

.cta-btn[class~=outline] {
  border: 1px solid #555555;
}

.cta-btn.outline {
  color: #555555;
  background: transparent;
  border-color: #555555;
}

.cta-btn.dark.outline {
  color: #555555;
  background: transparent;
  border-color: #555555;
}

.cta-btn.orange.outline {
  color: #ff6633;
  background: transparent;
  border-color: #ff6633;
}

.cta-btn.green.outline {
  color: #5cb865;
  background: transparent;
  border-color: #5cb865;
}

.cta-btn.red.outline {
  color: #d00014;
  background: transparent;
  border-color: #d00014;
}

.cta-btn.yellow.outline {
  color: #ffc107;
  background: transparent;
  border-color: #ffc107;
}

.cta-btn.blue.outline {
  color: #0099ff;
  background: transparent;
  border-color: #0099ff;
}

.cta-btn.pill {
  border-radius: 100px;
}

.cta-btn.dark.fancy {
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
  background: #555555;
  background-image: linear-gradient(dimgray, #3c3c3c);
}

.cta-btn.dark:hover,
.cta-btn.dark:active {
  background: #434343;
}

.cta-btn.outline:hover,
.cta-btn.outline:active,
.cta-btn.dark.outline:hover,
.cta-btn.dark.outline:active {
  background: rgba(85, 85, 85, 0.1);
}

.cta-btn.orange.fancy {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  background: #ff6633;
  background-image: linear-gradient(#ff855c, #ff531a);
}

.cta-btn.orange:hover,
.cta-btn.orange:active {
  background: #ff4b0f;
}

.cta-btn.orange.outline:hover,
.cta-btn.orange.outline:active {
  background: rgba(255, 102, 51, 0.1);
}

.cta-btn.green.fancy {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  background: #5cb865;
  background-image: linear-gradient(#78c480, #4cae56);
  color: #fff;
}

.cta-btn.green:hover,
.cta-btn.green:active {
  background: #49a752;
}

.cta-btn.green.outline:hover,
.cta-btn.green.outline:active {
  background: rgba(92, 184, 101, 0.1);
}

.cta-btn.red.fancy {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  background: #d00014;
  background-image: linear-gradient(#f90018, #b70012);
}

.cta-btn.red:hover,
.cta-btn.red:active {
  background: #ac0011;
}

.cta-btn.red.outline:hover,
.cta-btn.red.outline:active {
  background: rgba(208, 0, 20, 0.1);
}

.cta-btn.blue.fancy {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  background: #0099ff;
  background-image: linear-gradient(#29a9ff, #008ae6);
}

.cta-btn.blue:hover,
.cta-btn.blue:active {
  background: #24a7ff;
}

.cta-btn.blue.outline:hover,
.cta-btn.blue.outline:active {
  background: rgba(0, 153, 255, 0.1);
}

.cta-btn.yellow.fancy {
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  background: #ffc107;
  background-image: linear-gradient(#ffce3a, #edb100);
}

.cta-btn.yellow:hover,
.cta-btn.yellow:active {
  background: #ffca2b;
}

.cta-btn.yellow.outline:hover,
.cta-btn.yellow.outline:active {
  background: rgba(255, 193, 7, 0.1);
}

.scroll-anim {
  opacity: 0;
  position: relative;
}

.fade-in {
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.slide-in-left {
  animation: slideInLeftAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes slideInLeftAnimation {
  0% {
    opacity: 0;
    left: -50%;
  }
  75% {
    left: 0;
  }
  100% {
    opacity: 1;
  }
}
.slide-in-right {
  animation: slideInRightAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes slideInRightAnimation {
  0% {
    opacity: 0;
    right: -50%;
  }
  75% {
    right: 0;
  }
  100% {
    opacity: 1;
  }
}
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
}

body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #555555;
}

.wrap {
  padding: 30px 0;
}

.container-fluid.but-fixed {
  max-width: 1230px;
}

#footer-wrap {
  background-color: #f5f5f5;
}

.footer {
  padding-top: 30px;
  padding-bottom: 15px;
  width: 100%;
  color: #6a6a6a;
  border-top: 1px solid #e4e4e4;
  font-size: 0.9rem;
}
.footer p {
  margin: 0 0 5px 0;
}
.footer a {
  color: #6a6a6a;
}
.footer img {
  display: inline-block;
}

.fw-strong {
  font-weight: 600;
}

.border-light {
  border-color: #efefef !important;
}

.bg-light-blue {
  background: #f6f9fb !important;
}

/* --------- asc styles --------- */
#header {
  float: none;
}
#header a.logo {
  float: left;
  margin-top: 15px;
  margin-bottom: 15px;
  border: 0;
  width: 80%;
  max-width: 200px;
}
#header a.logo img {
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
  width: auto;
  height: auto;
  max-width: 200px;
}

#banner-wrap .homepage-banner {
  width: 300px;
}
#banner-wrap .details ul,
#banner-wrap .details p {
  font-size: 1.1em;
}

#steps-wrap .icon-border {
  width: 40px;
  height: 40px;
  border: 1px solid #05446b;
  border-radius: 50%;
}
#steps-wrap .icon {
  width: 20px;
  height: auto;
  vertical-align: middle;
  fill: #05446b;
}

#circle-icon {
  width: 180px;
}

.dropdown-list.dropdown-cta.small-cta {
  max-width: 250px;
}

#cta-wrap,
.gradient-wrap {
  background: #05446b;
  background: linear-gradient(30deg, #05446b, #05446b 42%, #0092b2);
}

#cta-wrap .dropdown-list {
  width: 95%;
  max-width: 300px;
}
#cta-wrap .dropdown-list.small-cta {
  max-width: 250px;
}

.gradient-wrap a {
  color: #70d7ed;
}

blockquote {
  font-size: 1.35rem;
}
blockquote p {
  font-style: italic;
  line-height: 1.5em;
}
blockquote p:before, blockquote p:after {
  margin-right: 5px;
  display: inline-block;
  content: "“";
  opacity: 0.5;
  font-family: serif;
  font-size: 32px;
  font-weight: 700;
  vertical-align: middle;
  font-style: normal;
  line-height: initial;
  height: 20px;
  margin-top: -13px;
}
blockquote p:after {
  margin-left: 5px;
  content: "”";
}

.card {
  border-radius: 0.5rem;
  box-shadow: 0 0.1rem 0.4rem rgba(0, 0, 0, 0.18);
  border-top: 5px solid #0092b2;
  border-bottom: none;
  border-left: none;
  border-right: none;
}
.card .card-header {
  background: #fff;
  border-radius: 0.8rem 0.8rem 0 0;
}
.card .card-footer {
  border-radius: 0 0 0.8rem 0.8rem;
}

.radio-btn {
  border: 1px solid #05446b;
  max-width: 300px;
  color: #05446b;
  background: rgba(255, 255, 255, 0.75);
  cursor: pointer;
}
.radio-btn.active {
  background: #05446b;
  color: #fff;
}
.radio-btn .form-check-label > span {
  font-size: 1.15em;
  line-height: 1.3em;
}

#banner-wrap.food-handler {
  background: #fff url(../images/banners/food-handler-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.food-manager {
  background: #fff url(../images/banners/food-manager-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.alcohol-certification {
  background: #fff url(../images/banners/alcohol-certification-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.seller-server-alcohol {
  background: #05446b url(../images/banners/seller-server-alcohol-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.california {
  background: #05446b url(../images/banners/california-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.texas-food-handler {
  background: #05446b url(../images/banners/texas-food-handler-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.texas-food-manager {
  background: #05446b url(../images/banners/texas-food-manager-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.illinois-home {
  background: #fff url(../images/banners/illinois/home-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.il-on-premise {
  background: #fff url(../images/banners/illinois/on-premise2-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.il-off-premise {
  background: #fff url(../images/banners/illinois/off-premise2-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.food-handler-state {
  background: #fff url(../images/banners/food-handler-state-sm.jpg) no-repeat 50% 50%/cover;
}
#banner-wrap.ny-on-premise {
  background: #fff url(../images/banners/new-york/on-premise-sm.jpg) no-repeat center/cover;
}
#banner-wrap.ny-off-premise {
  background: #fff url(../images/banners/new-york/off-premise-sm.jpg) no-repeat center/cover;
}
#banner-wrap.tabc-packages {
  background: #fff url(../images/banners/tabc-packages-bg-sm.jpg) no-repeat 47% 25%/cover;
}
#banner-wrap.utah-easy {
  background: #fff url(../images/banners/utah-off-premise-sm.jpg) no-repeat 0% 30%/cover;
}
#banner-wrap .card {
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
}

.dropdown-list .dropdown {
  transition: 0.2s;
}
.dropdown-list .dropdown.is-active {
  background: #0092b2;
  color: #fff;
}
.dropdown-list .dropdown.is-active a {
  color: #fff;
}

.dropdown-bundle {
  min-width: 300px;
}

.bundle-banner a {
  color: #7fe8ff;
}
.bundle-banner.illinois {
  background: #05446b url(../images/banners/illinois/off-premise-bundle-bg-sm.jpg) no-repeat 22% 22%/cover;
}
.bundle-banner.slsv {
  background: #05446b url(../images/banners/seller-server-bundle-bg-sm.jpg) no-repeat 0% 100%/cover;
}

.list-price {
  margin-left: 3px;
  position: relative;
  font-size: 30px;
  font-weight: 300;
  display: inline-block;
  font-family: "Trebuchet MS", "Droid Serif", Arial, sans-serif;
  vertical-align: middle;
}
.list-price:after {
  display: block;
  content: "";
  width: 110%;
  height: 2px;
  background: #c33;
  position: absolute;
  top: 48%;
  left: -5%;
  transform: rotate(-10deg);
  transform-origin: 40% 0;
}
.list-price span {
  color: #c0c0c0;
}
.list-price span.dollar-sign {
  margin: 0;
  font-size: 20px;
  display: inline-block;
  line-height: 40px;
  vertical-align: top;
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
@media (min-width: 576px) {
  #steps-wrap .icon-border {
    width: 60px;
    height: 60px;
  }
  #steps-wrap .icon {
    width: 30px;
    height: auto;
  }
  .btn-width {
    width: 220px;
  }
}
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header a.logo img {
    display: block;
    float: left;
    max-width: 200px;
    margin-right: 15px;
  }
  #banner .homepage-banner {
    width: 500px;
  }
  #banner-wrap.food-handler {
    background: #fff url(../images/banners/food-handler.jpg) no-repeat 0 0/cover;
  }
  #banner-wrap.food-handler-state {
    background: #fff url(../images/banners/food-handler-state.jpg) no-repeat 35% 0/cover;
  }
  #banner-wrap.food-handler-state.illinois {
    background-position: 0 0;
  }
  #banner-wrap.food-manager {
    background: #fff url(../images/banners/food-manager.jpg) no-repeat 0 20%/cover;
  }
  #banner-wrap.alcohol-certification {
    background: #fff url(../images/banners/alcohol-certification.jpg) no-repeat 0 0/cover;
  }
  #banner-wrap.texas-food-handler {
    background: #05446b url(../images/banners/texas-food-handler.jpg) no-repeat 0 0/cover;
  }
  #banner-wrap.texas-food-manager {
    background: #05446b url(../images/banners/texas-food-manager.jpg) no-repeat 0 0/cover;
  }
  #banner-wrap.california {
    background: #05446b url(../images/banners/california.jpg) no-repeat 0 0/cover;
  }
  #banner-wrap.seller-server-alcohol {
    background: #05446b url(../images/banners/seller-server-alcohol.jpg) no-repeat center/cover;
  }
  #banner-wrap.illinois-home {
    background: #fff url(../images/banners/illinois/home.jpg) no-repeat center/cover;
  }
  #banner-wrap.il-on-premise {
    background: #fff url(../images/banners/illinois/on-premise.jpg) no-repeat center/cover;
  }
  #banner-wrap.il-off-premise {
    background: #fff url(../images/banners/illinois/off-premise.jpg) no-repeat center/cover;
  }
  #banner-wrap.ny-on-premise {
    background: #fff url(../images/banners/new-york/on-premise.jpg) no-repeat center/cover;
  }
  #banner-wrap.ny-off-premise {
    background: #fff url(../images/banners/new-york/off-premise.jpg) no-repeat center/cover;
  }
  #banner-wrap.tabc-packages {
    background: #fff url(../images/banners/tabc-packages-bg.jpg) no-repeat 0 5%/cover;
  }
  #banner-wrap.utah-easy {
    background: #fff url(../images/banners/utah-off-premise.jpg) no-repeat 75% 100%/cover;
  }
  #steps-wrap .icon-border {
    width: 80px;
    height: 80px;
    border-width: 2px;
  }
  #steps-wrap .icon {
    width: 40px;
    height: auto;
  }
}
@media (min-width: 992px) {
  #steps-wrap .icon-border {
    width: 120px;
    height: 120px;
    border-width: 3px;
  }
  #steps-wrap .icon {
    width: 55px;
    height: auto;
  }
  .bundle-banner.illinois {
    background: #05446b url(../images/banners/illinois/off-premise-bundle-bg.jpg) no-repeat 80% 25%/cover;
  }
  .bundle-banner.slsv {
    background: #05446b url(../images/banners/seller-server-bundle-bg.jpg) no-repeat right 85%/cover;
  }
}
@media (min-width: 1200px) {
  #nav-wrap {
    border-bottom: 0;
  }
  #nav-wrap .login a {
    background: #0092b2;
    border-radius: 50px;
    padding: 5px 15px;
  }
  #nav-wrap .login a:hover {
    background: #007d99;
  }
  #banner-wrap.food-handler-state {
    background: #fff url(../images/banners/food-handler-state.jpg) no-repeat right 0/cover;
  }
  #banner-wrap.food-handler-state.illinois {
    background-position: 0 0;
  }
}
@media (min-width: 1500px) {
  #banner-wrap.large-img {
    background-size: contain !important;
    background-position: 100% 50% !important;
  }
}
@media (min-width: 2000px) {
  #banner-wrap.large-img {
    background-size: cover !important;
    background-position: 100% 20% !important;
  }
}